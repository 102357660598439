<template>
  <div class="look-train-data-box">
    <page-main title back>
      <el-row type="flex" justify="space-between">
        <el-row type="flex">
          <div class="scene-cover" title="点击预览" @click="showVideoModal = true">
            <img :src="sceneDetail.coverUrl" alt="" style="object-fit: cover; width: 100%; height: 100%;">
            <span class="time-wrapper">
              <i class="el-icon-view mgn-r8" />
              预览视频
            </span>
          </div>
          <div style="min-width: 150px;">
            <div class="flex-between flex-direction-col vertical-start">
              <div class="col272e40 font-s18 font-bold">{{ sceneDetail.title }}</div>
            </div>
            <div class="mgn-t10 col7784a1 font-s14">
              <span>播客：共{{ speakerList.length }}位嘉宾</span>
              <span v-if="speakerList.length" class="mgn-l10 text-primary-link" @click="onShowGuest">查看嘉宾</span>
            </div>

            <el-row v-if="isShowBtn" type="flex" class="flex-wrap mgn-t10">
              <span class="col7784a1 font-s14">产品标签：</span>
              <template v-for="item in productList">
                <span :key="item.tagId" class="scene-tag">{{ item.tagName }}</span>
              </template>
              <span v-if="!productList.length > 0" class="col7784a1 font-s14" style="margin-top: 8px;">/</span>
            </el-row>
            <el-row v-if="otherList.length > 0 && isShowBtn" type="flex" class="flex-wrap mgn-t10">
              <span class="col7784a1 font-s14">其它标签：</span>
              <template v-for="item in otherList">
                <span v-if="item.groupName !== '产品标签'" :key="item.tagId" class="scene-tag">{{ item.tagName }}</span>
              </template>
            </el-row>
            <div v-if="sceneDetail.projectName" class="col7784a1 mgn-t10 font-s14">关联项目：{{ sceneDetail.projectName }}</div>
            <div v-if="sceneDetail.strategyName" class="col7784a1 mgn-t10 font-s14">策略：{{ sceneDetail.strategyName }}</div>
            <div class="col7784a1 mgn-t10 font-s14">简介：{{ sceneDetail.intro }}</div>
            <div v-if="videoParseStatus === 1" class="col7784a1 mgn-t10 font-s14">AI 解析内容：<el-button @click="toVideoParse">点击查看</el-button></div>
            <div class="col7784a1 mgn-t10 font-s14">
              <!-- 视频时长{{ duration >= 60 ? parseInt(duration / 60) + '分' + (duration % (parseInt(duration / 60) * 60)) + '秒' : duration + '秒' }}，
              科会观看时长要求{{ durationNeed >= 60 ? parseInt(durationNeed / 60) + '分' + (durationNeed % (parseInt(durationNeed / 60) * 60)) + '秒' : durationNeed + '秒' }} -->
            </div>
          </div>
        </el-row>
        <div class="funnel">
          <EchartsFunnel v-if="showFunnel" :option="option" />
        </div>
        <div class="icon-message-box">
          <el-popover placement="left" width="400" trigger="hover">
            <div>总活动任务：实际发生的任务，不包含手动撤回和过期撤回的任务</div>
            <div>已开始任务：代表发送给客户的任务数</div>
            <div>已完成学术任务：客户学习并完成的任务数</div>
            <img
              slot="reference"
              src="../../assets/iconImg/icon-mark.png"
              alt=""
              style="width: 100%; height: 100%; cursor: pointer;"
            >
          </el-popover>
        </div>
      </el-row>
    </page-main>
    <page-main style="margin-top: 0;">
      <el-row
        type="flex"
        justify="space-between"
        align="middle"
        class="flex-wrap"
      >
        <span class="font-s16">数据报表</span>
        <el-row v-if="$auth('acadSaas.actMgmt.viewExecData')" type="flex">
          <el-button
            size="mini"
            type="primary"
            class="w120"
            plain
            @click="onJumpPanGu('rank')"
          >
            执行报表
          </el-button>
          <el-button
            size="mini"
            type="primary"
            class="w120"
            plain
            @click="onJumpPanGu('rank', 'Represent')"
          >
            代表数据报表
          </el-button>
          <el-button
            size="mini"
            type="primary"
            class="w120"
            plain
            @click="onJumpPanGu('doctor/data')"
          >
            医生数据报表
          </el-button>
          <el-button
            size="mini"
            type="primary"
            class="w120"
            plain
            @click="onJumpPanGu('task/data')"
          >
            任务数据报表
          </el-button>
          <el-button
            size="mini"
            type="primary"
            class="w120"
            plain
            @click="onJumpPanGu('exam/data')"
          >
            答题报表
          </el-button>
        </el-row>
      </el-row>
    </page-main>
    <page-main style="margin-top: 0;">
      <el-tabs v-model="activeTab">
        <el-tab-pane
          v-for="item in tabList"
          :key="item.key"
          :label="item.name"
          :name="item.key"
        />
      </el-tabs>
      <div class="table-box">
        <div v-if="activeTab === 'exam'">
          <EntrustListDetail
            v-if="sceneId && sceneDetail.activityQuestId"
            :id="sceneDetail.activityQuestId"
            :scene-id="sceneId"
          />
          <div v-else class="scene-empty">
            <div><img :src="require('@/assets/common/empty.png')" alt=""></div>
            <div>当前科会未添加题目</div>
          </div>
        </div>
        <div v-if="activeTab === 'allot'">
          <el-table :data="recordList">
            <el-table-column label="分配时间" prop="createdAt">
              <template slot-scope="scope">
                <span>{{ $moment(scope.row.createdAt).format('YYYY-MM-DD HH:mm:ss') }}</span>
              </template>
            </el-table-column>
            <el-table-column label="分配方式" align="center">
              <template slot-scope="scope">
                <span>分配给{{ scope.row.toType | allotUserType }}{{ scope.row.toType === 1 && scope.row.autoAttrTime ? `（${$moment(scope.row.autoAttrTime).format('YYYY-MM-DD HH:mm:ss')}）` : '' }} </span>
              </template>
            </el-table-column>
            <el-table-column
              label="任务规则"
              align="center"
            >
              <template slot-scope="scope">
                <div>代表执行{{ scope.row.validDayRepresent }}天 + 医生学习{{ scope.row.validDayDoctor }}天</div>
              </template>
            </el-table-column>
            <el-table-column label="任务总数" align="center">
              <template slot-scope="scope">
                <span>{{ scope.row.codeCount }}个</span>
              </template>
            </el-table-column>
            <el-table-column label="成功任务数" align="center">
              <template slot-scope="scope">
                <span>{{ scope.row.successCount }}个</span>
              </template>
            </el-table-column>
            <el-table-column label="失败任务数" align="center">
              <template slot-scope="scope">
                <span>{{ scope.row.failCount }}个</span>
              </template>
            </el-table-column>
            <el-table-column label="是否有委托" align="center">
              <template slot-scope="scope">
                <span>{{ scope.row.hasDelegate ? '是' : '否' }}</span>
              </template>
            </el-table-column>
            <el-table-column label="分配状态" align="center">
              <template slot-scope="scope">
                <span>{{ scope.row.status === 1 ? '分配完成' : scope.row.status === 2 ? '分配中' : scope.row.status === 3 ? '分配失败' : '' }}</span>
              </template>
            </el-table-column>
            <el-table-column label="分配操作人" prop="userName" align="center" />
            <el-table-column label="操作" align="center">
              <template slot-scope="scope">
                <el-popover
                  v-model="openObj[scope.row.id]"
                  width="200"
                  trigger="manual"
                  placement="left"
                >
                  <div v-if="exportMsg" class="card-body">{{ exportMsg }}</div>
                  <div v-else class="card-body">是否下载当前分配记录？</div>
                  <el-row type="flex" justify="end">
                    <el-button size="mini" type="text" @click="onCloseModal">取消</el-button>
                    <el-button
                      v-if="exportMsg"
                      type="primary"
                      size="mini"
                      @click="onJumpDownCenter"
                    >
                      前往
                    </el-button>
                    <el-button
                      v-else
                      type="primary"
                      size="mini"
                      :loading="openObj[scope.row.id] === 'loading'"
                      @click="onDown(scope.row.id)"
                    >
                      确定
                    </el-button>
                  </el-row>
                  <span slot="reference" class="text-primary-link" @click.stop="onShowDownToast(scope.row.id, true)">下载分配记录表</span>
                </el-popover>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div v-if="activeTab === 'comment'">
          <el-row
            v-if="canSetting"
            type="flex"
            align="middle"
            style="padding-bottom: 10px; padding-top: 10px;"
          >
            <div>互动评论设置：</div>
            <el-radio-group v-model="commentType" @change="onChangeCommentType">
              <el-radio :label="0">仅展示精选评论</el-radio>
              <el-radio :label="1">展示所有评论</el-radio>
            </el-radio-group>
          </el-row>
          <scene-comment-item
            v-for="item in commentList"
            :key="item.commentId"
            :item="item"
            :scene-id="sceneId"
            :scene-type="sceneTypeId"
            :can-setting="canSetting"
            @onShield="onShieldComment(item.commentId, !item.status)"
            @onSetTop="onTopComment(item.commentId, !item.topCommentStatus)"
            @onSetActive="onActiveComment(item.commentId, !item.selectiveCommentStatus)"
          />
          <Empty v-if="showEmpty" text="暂无评论" />
          <el-row type="flex" justify="end" class="mgn-t10">
            <el-pagination
              :current-page="pageNo"
              :page-size="pageSize"
              background
              hide-on-single-page
              :total="commentTotalCount"
              @current-change="handleCurrentChange"
            />
          </el-row>
        </div>
      </div>
    </page-main>

    <fixed-action-bar>
      <el-button v-if="$auth('acadSaas.actMgmt.assignTask')" type="primary" @click="onAllot">分配任务</el-button>
      <template v-if="sceneSource">
        <el-button
          v-if="totalCodeCount - beginCodeCount > 0 && $auth('acadSaas.actMgmt.assignTask')"
          plain
          type="primary"
          @click="isShowRecallPop = true"
        >
          撤回任务
        </el-button>
        <el-button
          v-if="isShowBtn && $auth('acadSaas.actMgmt.edit')"
          plain
          type="primary"
          @click="onEdit"
        >
          编辑活动
        </el-button>
        <el-button
          v-if="isShowBtn && $auth('acadSaas.actMgmt.del')"
          plain
          type="primary"
          @click="onOpenOperationConfirmPop"
        >
          删除活动
        </el-button>
        <el-button
          v-if="$auth('acadSaas.actMgmt.share')"
          plain
          type="primary"
          @click="isShowSharePop = true"
        >
          分享活动
        </el-button>
        <el-button
          v-if="isShowBtn && $auth('acadSaas.actMgmt.etag')"
          plain
          type="primary"
          @click="showTagModal = true"
        >
          编辑标签
        </el-button>
        <ReportEntry v-slot="{reportList, checkReport}" :push-location="3">
          <el-button
            v-if="isShowBtn && reportList.length"
            plain
            type="primary"
            @click="checkReport({title: sceneDetail.title, sceneId})"
          >
            查看数据报告
          </el-button>
        </ReportEntry>
      </template>
    </fixed-action-bar>
    <SceneShareDepModal
      :visible="isShowSharePop"
      :scene-type="sceneType"
      :is-creator="sceneDetail.isCreator"
      :scene-id="sceneId"
      :is-submit="isShareLoading"
      @onSubmit="onSubmitShareDep"
      @onClose="onCloseModal"
    />
    <RecallSceneCodeModal
      :visible="isShowRecallPop"
      :scene-id="sceneId"
      :scene-type="sceneType"
      @onClose="onCloseModal"
    />
    <OperationConfirmPop
      :id="sceneId"
      :visible="isShowOperationConfirmPop"
      :message="message"
      title="删除操作确认"
      cancel-font="取消"
      confirm-font="确定删除"
      @onSubmit="onDel"
      @onClose="onCloseModal"
    />
    <video-play-modal
      :video-url="videoUrl"
      :visible="showVideoModal"
      title="科会视频"
      @onClose="onCloseModal"
    />
    <ProductLabel
      :form.sync="form"
      :visible.sync="showTagModal"
      :disabled-ids="disabledIds"
      :selected-tag-list="sceneTagList"
      @onSubmit="onSubmitTag"
    />
    <WebinarSpeakerListModal
      :visible="isShowGuestModal"
      :speaker-list="speakerList"
      @onClose="onCloseModal"
    />
  </div>
</template>

<script>
import Empty from '../academic/tim/components/empty'
import { mapActions, mapGetters } from 'vuex'
import OperationConfirmPop from '@/bizComponents/OperationConfirmPop'
import EchartsFunnel from '@/baseComponents/EchartsFunnel.vue'
import SceneCommentItem from './child/SceneCommentItem'
import RecallSceneCodeModal from '@/bizComponents/RecallSceneCodeModal'
import SceneShareDepModal from '@/bizComponents/SceneShareDepModal'
import VideoPlayModal from '@/bizComponents/VideoPlayModal'
import ProductLabel from '@/bizComponents/ProductLabel'
import EntrustListDetail from './components/EntrustListDetail.vue'
import WebinarSpeakerListModal from './child/WebinarSpeakerListModal'

export default {
  name: 'MeetingDetail', // 跟文件名称对应
  components: {
    SceneShareDepModal,
    RecallSceneCodeModal,
    EntrustListDetail,
    Empty,
    OperationConfirmPop,
    SceneCommentItem,
    EchartsFunnel,
    VideoPlayModal,
    ProductLabel,
    WebinarSpeakerListModal
  },
  filters: {
    allotUserType(type) {
      switch (type) {
        case 1:
          return '医生'
        case 2:
          return '代表'
        case 3:
          return '组织'
      }
    }
  },
  data() {
    return {
      dialogTableVisible: false, // 发送手机预览modal
      bindTagLoading: false,
      pageNo: 1,
      isShareLoading: false,
      canSetting: false,
      showEmpty: false,
      pageSize: 10,
      commentTotalCount: 0,
      commentType: 1,
      showTagModal: false,
      commentList: [],
      showFunnel: false,
      commentSettingId: '',
      showVideoModal: false,
      isShowGuestModal: false,
      tabList: [{
        name: '委托调研题',
        key: 'exam'
      }, {
        name: '分配记录表',
        key: 'allot'
      }, {
        name: '互动评论',
        key: 'comment'
      }],
      activeTab: 'exam',
      canEdit: false,
      userTagType: 5, // 标签分类
      sceneTagList: [],
      disabledIds: [],
      openObj: {},
      exportMsg: '',
      isShowSharePop: false,
      isShowRecallPop: false,
      isShowOperationConfirmPop: false,

      message: '',
      sceneId: '',
      sceneDetail: {},
      isClick: false,
      sceneType: 'meeting',
      sceneTypeId: 1,
      recordList: [],
      summaryData: [
        { value: 60, name: '总活动任务：' },
        { value: 40, name: '已开始任务：' },
        { value: 20, name: '已完成学术任务：' }
      ],
      totalCodeCount: 0,
      beginCodeCount: 0,
      productList: [],
      otherList: [],
      option: {},
      form: {},
      videoParseStatus: null,
      speakerList: []
    }
  },
  computed: {
    ...mapGetters(['tagGroupList', 'academicSourceType']),
    isShowBtn() {
      return this.academicSourceType === 'ower'
    },
    videoUrl() {
      const videoData = this.sceneDetail.videoUrl || []
      let currentUrl = ''
      videoData.map(v => {
        if (v.indexOf('ld.mp4')) {
          currentUrl = v
        }
      })
      return currentUrl
    },
    sceneSource() {
      return this.$route.query.from !== 'common'
    },
    duration() {
      return Math.round(this.sceneDetail.duration) || 0
    },
    durationNeed() {
      return Math.ceil(this.duration * 0.95) || 0
    }
  },
  watch: {
    activeTab(newVal) {
      // this.selectedTagList = []
      if (newVal === 'allot') {
        this.initRecord()
      }
      if (newVal === 'comment') {
        this.initComment()
      }
    },
    sceneTagList: {
      deep: true,
      immediate: true,
      handler() {
        this.productList = []
        this.otherList = []
        this.sceneTagList.map(item => {
          if (item.groupName === '产品标签') {
            this.productList.push(item)
          } else {
            this.otherList.push(item)
          }
        })
      }
    }
  },
  mounted() {
    this.sceneId = this.$route.params.sceneId
    this.canSetting = this.$route.query.isCreator == 'true'
    this.init()
  },
  methods: {
    ...mapActions(['getMeetingDetail', 'getSceneCodeUsedStatistic', 'getAcademicSceneCodeList', 'getSceneDetailTag', 'onDownSceneAllotRecordData', 'getSceneAllotRecordList', 'onDelMeeting', 'sceneShareToDep', 'onBindSceneTag', 'getSceneCommentList', 'onShieldSceneComment', 'onSetTopComment', 'onSetActiveComment', 'setCommentShowType', 'onShieldMeetingComment']),
    // 查看视频解析状态
    async getVideoParseStatus(videoId) {
      const params = { videoId }
      const res = await this.$axios.get(this.$API.queryStatus, {params})
      this.videoParseStatus = res.data
    },
    getOption() {
      this.option = {
        series: [{
          name: '活动数据',
          type: 'funnel',
          left: 0,
          top: 0,
          bottom: 0,
          width: 240,
          min: 0,
          max: 100,
          minSize: '0%',
          maxSize: '100%',
          gap: 0,
          label: {
            show: true,
            position: 'right',
            color: '#272E40'
          },
          labelLine: {
            length: 30,
            lineStyle: {
              width: 1,
              type: 'solid'
            }
          },
          color: [
            '#B1BFF3',
            '#8AA0EE',
            '#6380E8',
            '#3D61E3'
          ],
          data: this.summaryData
        }],
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b}'
        }
      }
      this.showFunnel = true
    },
    onChangeCommentType() {
      this.setCommentShowType({ sceneId: this.sceneId, sceneType: this.sceneTypeId, ruleType: this.commentType, commentSettingId: this.commentSettingId }).then(res => {
        this.commentSettingId = res
      })
    },
    onShowGuest() {
      this.isShowGuestModal = true
    },
    onSubmitTag(arr) {
      this.bindTagLoading = true
      const req = arr.map(v => {
        return {
          tagId: v.tagId,
          groupId: v.groupId,
          userTagType: this.userTagType,
          memberId: this.sceneId,
          knowledgePointIds: v.knowledgePointIds,
          strategyId: this.form.strategyId
        }
      })
      this.onBindSceneTag(req).then(
        () => {
          this.initTag()
          this.bindTagLoading = false
          this.showTagModal = false
          this.form.strategyDisabled = true
          this.$message.success('标签绑定成功')
        },
        rea => {
          this.showTagModal = false
          this.bindTagLoading = false
          this.$message.error(rea)
        }
      )
    },
    onShieldComment(commentId, isOpen) {
      this.onShieldSceneComment({ commentId, isOpen, sceneId: this.sceneId, sceneType: this.sceneTypeId }).then(() => {
        this.commentList = [...this.commentList].map(v => {
          if (v.commentId === commentId) {
            v = { ...v, status: isOpen }
          }
          return v
        })
      })
    },
    onTopComment(commentId, isTop) {
      this.onSetTopComment({ commentId, isTop, sceneId: this.sceneId, sceneType: this.sceneTypeId }).then(() => {
        this.commentList = [...this.commentList].map(v => {
          if (v.commentId === commentId) {
            v = { ...v, topCommentStatus: isTop }
          }
          return v
        })
      })
    },
    onActiveComment(commentId, isSelective) {
      this.onSetActiveComment({ commentId, isSelective, sceneId: this.sceneId, sceneType: this.sceneTypeId }).then(() => {
        this.commentList = [...this.commentList].map(v => {
          if (v.commentId === commentId) {
            v = { ...v, selectiveCommentStatus: isSelective }
          }
          return v
        })
      })
    },
    handleCurrentChange(page) {
      this.pageNo = page
      this.initComment()
    },
    initComment() {
      const opt = {
        pageNo: this.pageNo,
        sceneId: this.sceneId,
        sceneType: this.sceneTypeId
      }
      this.getSceneCommentList(opt).then(
        res => {
          this.commentList = res.data
          if (!this.commentList.length) {
            this.showEmpty = true
          }
          this.commentTotalCount = res.count
        },
        rea => {
          console.log(rea)
        }
      )
    },
    onJumpPanGu(path, type = '') {
      this.$qxsDataPageJump(`/${path}?sceneTitle=${encodeURIComponent(this.sceneDetail.title)}&sceneId=${this.sceneId}&rankType=${type}`)
    },
    initRecord() {
      this.getSceneAllotRecordList(this.sceneId).then(res => {
        this.recordList = res
      })
    },
    onShowDownToast(openKey = '') {
      this.openObj = { ...this.openObj, [openKey]: true }
    },
    onJumpDownCenter() {
      this.$router.push({name: 'DownloadCenter'})
    },
    onDown(id) {
      if (this.openObj[id] === 'loading') return
      this.openObj[id] = 'loading'
      const req = {
        codeAssignRecordId: id
      }
      this.onDownSceneAllotRecordData(req).then(
        res => {
          this.openObj[id] = false
          this.$message.success('数据下载成功，请前往下载中心查看')
          this.exportMsg = res.data.data
        },
        rea => {
          this.openObj[id] = false
          this.$message.error(rea.message)
        }
      )
    },
    onSubmitShareDep(arr, isSelectedPubResLib) {
      this.isShareLoading = true
      const req = {
        sceneIds: [this.sceneId],
        sceneType: this.sceneType,
        factoryIds: arr,
        isSelectedPubResLib
      }
      this.sceneShareToDep(req).then(
        () => {
          this.isShareLoading = false
          this.isShowSharePop = false
          this.$message.success('分享成功')
        },
        rea => {
          this.isShareLoading = false
          this.$message.error(rea.message)
        }
      )
    },
    init() {
      this.getMeetingDetail(this.sceneId).then(
        res => {
          this.sceneDetail = res
          this.commentSettingId = res.commentSettingId
          this.commentType = res.commentRuleType
          this.speakerList = res.activitySpeakerVOS?.map(v => {
            return {
              name: v.userName,
              titleName: v.titleName,
              avatarVO: v.avatarVO,
              hospital: v.hospitalName,
              department: v.department,
              userId: v.userId
            }
          })
          this.form.strategyId = res.strategyId
          this.form.strategyDisabled = !!res.strategyId

          this.getVideoParseStatus(this.sceneDetail.videoId)
          this.initTag()
          this.onCodeUsedStatistic()
        },
        message => {
          this.$message({
            message: message,
            type: 'error'
          })
        }
      )
    },
    initTag() {
      const groupIds = this.tagGroupList.map(v => v.id)
      const req = {
        memberIdList: [this.sceneId],
        userTagType: this.userTagType,
        tagType: 1,
        groupIds: groupIds
      }
      this.getSceneDetailTag(req).then(res => {
        this.disabledIds = []
        this.sceneTagList = res.map(v => {
          v = { ...v, disabled: true }
          this.disabledIds.push(v.tagId)
          return v
        })
      })
    },
    onCloseModal() {
      this.isShowRecallPop = false
      this.showVideoModal = false
      this.showTagModal = false
      this.openObj = {}
      this.isShowSharePop = false
      this.isShowRecallPop = false
      this.isShowOperationConfirmPop = false
      this.isShowGuestModal = false
    },
    onAllot() {
      this.$router.push(`/academic/scene/${this.sceneType}/rule/${this.sceneId}?disabledBack=${!this.sceneDetail.canEdit}`)
    },
    onEdit() {
      this.$router.push({
        name: 'EditMeeting',
        params: {
          id: this.sceneId
        }
      })
    },
    onOpenOperationConfirmPop() {
      this.message = this.sceneDetail.isRealCanDel ? '请确认是否删除，一经删除，不可恢复' : '请确认是否删除，删除后不可再分配，您也可以在个人中心回收站重新找回'
      this.isShowOperationConfirmPop = true
    },
    onCodeUsedStatistic() {
      this.getSceneCodeUsedStatistic(this.sceneId).then(res => {
        this.totalCodeCount = res.data.totalCodeCount
        this.beginCodeCount = res.data.beginCodeCount
        this.summaryData = [...this.summaryData].map((v, i) => {
          let name = ''
          if (i === 0) {
            name = `总活动任务：${ res.data.totalCodeCount }个`
          } else if (i === 1) {
            name = `已开始任务：${ res.data.beginCodeCount }个`
          } else if (i === 2) {
            name = `已完成学术任务：${ res.data.finishedCodeCount }个`
          }
          v = {...v, name: name}
          return v
        })
        this.getOption()
      }, rea => {
        this.$message.error(rea.message)
      })
    },
    onDel() {
      this.onDelMeeting(this.sceneId).then(
        () => {
          this.$message({
            message: '删除成功！',
            type: 'success'
          })
          this.$router.back()
          this.onCloseModal()
        },
        rea => {
          this.$message({
            message: rea,
            type: 'error'
          })
        }
      )
    },
    toVideoParse() {
      const url = this.$router.resolve({
        path: '/sub-app/common-page/ai-parse',
        query: {
          id: this.sceneDetail.videoId
        }
      })
      window.open(url.href, '_blank')
    }
  }
}
</script>

<style lang="scss" scoped>
.content-box {
  position: relative;
  flex: 1;
  overflow-y: scroll;
  padding: 20px;
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;
}
.content-box::-webkit-scrollbar {
  width: 0 !important;
}
.card-body ::v-deep {
  padding: 12px 14px;
  position: relative;
  flex-shrink: 0;
  .el-tabs {
    margin-top: -32px;
  }
  .el-tabs__header {
    margin-left: -34px;
    margin-right: -34px;
    .el-tabs__nav-wrap {
      padding-left: 34px;
    }
  }
}
.table-box {
  min-height: 400px;
}
.out-progress {
  width: 140px;
  height: 10px;
  background: #f0f0f0;
  border-radius: 7px;
}
.in-progress-doing {
  height: 10px;
  background: linear-gradient(270deg, #3dc8fb 0%, #417bf3 100%);
  border-radius: 7px;
}
.in-progress-end {
  height: 10px;
  background: linear-gradient(270deg, #00ea6e 0%, #14b4c2 100%);
  border-radius: 7px;
}
.video-cover {
  width: 107px;
  height: 60px;
  background: #d8d8d8;
  margin: auto;
}
.box-shadow {
  box-shadow: 2px -2px 4px 0 #e2e3e7;
}
.title-nav-box {
  position: relative;
  z-index: 10;
}
.footer {
  background: #fff;
  height: 100px;
  padding-right: 100px;
  border-top: 1px solid #e9eaeb;
}
.circle {
  width: 16px;
  height: 16px;
  border: 2px solid #d7dce1;
  border-radius: 50%;
  margin-right: 5px;
}
.question {
  line-height: 60px;
  width: 100%;
  color: #909399;
  text-align: center;
}
.funnel {
  height: 170px;
  width: 440px;
  flex-shrink: 0;
  position: relative;
}
.scene-tag {
  color: #3d61e3;
  background: rgba(61, 97, 227, 0.12);
  border-radius: 16px;
  height: 20px;
  flex-shrink: 0;
  line-height: 12px;
  padding: 4px 10px;
  margin-right: 10px;
}
.icon-message-box {
  width: 24px;
  height: 24px;
  position: absolute;
  z-index: 100;
  right: 15px;
}
.scene-cover {
  flex-shrink: 0;
  margin-right: 30px;
  position: relative;
  cursor: pointer;
  width: 320px;
  height: 180px;
  background: #d8d8d8;
}
.time-wrapper {
  font-size: 12px;
  color: #fff;
  height: 22px;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  margin-left: 5px;
  position: absolute;
  right: 0;
  bottom: 0;
}
.radio-none-lable {
  ::v-deep .el-radio__label {
    display: none;
  }
}
.scene-empty {
  color: rgba(119, 132, 161, 1);
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  img {
    margin-bottom: 10px;
  }
}
</style>
